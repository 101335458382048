import React, { useState, FormEvent } from "react";
import PricingHeroImage from "../../../assets/reportAnalytics.svg";
import Input from "../input";
import Button from "../../shared-components/Button";
import { ArrowRightIcon } from "../../../assets/svg";
import { motion } from "framer-motion";
import jsonp from "jsonp";

const PricingHero: React.FC = () => {
  const [firstName, setFirstName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const existingEmails = ["test1@example.com", "test2@example.com"];

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    // Validation
    if (!firstName || !email) {
      setError("Please fill out all fields.");
      return;
    }

    if (existingEmails.includes(email)) {
      setError("This email already exists. Please use a different email.");
      return;
    }

    setError("");

    const url =
      "https://emeralddata.us13.list-manage.com/subscribe/post?u=af2935c91e17b5478cb4670d8&amp;id=e1bb4310cc&amp;f_id=002fbbe1f0";

    jsonp(
      `${url}&EMAIL=${email}&FNAME=${firstName}`,
      { param: "c" },
      (error: any, data: { msg: string }) => {
        if (!error) {
          setIsSubmitted(true);
        }
      }
    );
    setIsSubmitted(true);
  };

  return (
    <section
      className="flex flex-col lg:flex-row items-center lg:items-start overflow-hidden"
      style={{
        background: "linear-gradient(180deg, #FBFBFD 0%, #F9FBFE 100%)",
      }}
    >
      <div className="w-full md:py-[11.5rem] py-12 mb-12 lg:mb-0 text-center lg:text-left container flex flex-col items-center lg:flex-row lg:h-[55rem]">
        <div className="w-full">
          <motion.div
            initial={{ x: "-100%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className="w-full"
          >
            <h1 className="md:text-5xl text-xl leading-7 font-semibold md:leading-9 text-gray-900 lg:max-w-[33rem] w-full font-inter">
              Transform Your Operations with <br />
              <span className="text-green-500">Emerald!</span>
            </h1>
            <p className="md:mt-6 mt-6 md:text-lg text-xs font-normal lg:text-left text-center text-gray-800 lg:max-w-[36.8rem] w-full md:leading-7 leading-6 font-inter">
              Join our wishlist to discover how Emerald can streamline your
              processes. Be at the forefront of operational excellence.
            </p>

            {/* Conditionally render form or thank you message */}
            {!isSubmitted ? (
              <form
                className="flex flex-col items-start md:mt-[2.12rem] mt-4"
                onSubmit={handleSubmit}
              >
                <Input
                  label="Full Name"
                  placeholder="Full Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <Input
                  label="Email Address"
                  placeholder="Enter Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                {error && <p className="mt-4 text-red-600">{error}</p>}

                <Button
                  variant="default"
                  iconPosition="end"
                  icon={<ArrowRightIcon />}
                  className="max-w-full mt-6 md:w-[11.5rem] h-[3rem] w-[22rem]"
                  type="submit"
                >
                  <span>Join Wish List</span>
                </Button>
              </form>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: "easeOut" }}
                className="flex flex-col  mt-12"
              >
                <motion.div
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 1 }}
                  className="flex justify-center max-w-[400px]"
                >
                  <iframe
                    src="https://lottie.host/embed/93e1ef98-6d9e-46bc-a211-15b7972f2beb/auzg1hhFMt.json"
                    className="w-[100px] h-[100px]"
                    title="lottieFile"
                  ></iframe>
                </motion.div>

                <motion.h2
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, ease: "easeOut" }}
                  className="mt-2 text-green-500 text-2xl font-semibold"
                >
                  Thank you for joining the wishlist!
                </motion.h2>
              </motion.div>
            )}
          </motion.div>
        </div>
        <div className="w-full flex justify-center relative">
          <motion.div
            initial={{ x: "100%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className="md:mt-0 mt-4"
          >
            <img src={PricingHeroImage} alt="Hero Graphic" className="w-full" />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default PricingHero;
