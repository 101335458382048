import React from "react";
import PricingHero from "./pricingHero";
import PricingSection from "./pricingSection";
import ComparePricing from "./comparePricing";
import Faqs from "../Faqs";

const PricingPage = () => {
  return (
    <div>
      <PricingHero />
      <PricingSection />
      <div className="hidden md:block">
        <ComparePricing />
      </div>

      <div className="md:pt-20 mb-20">
        <Faqs />
      </div>
    </div>
  );
};

export default PricingPage;
