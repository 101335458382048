import React, { useState, useRef, useEffect } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

interface FAQ {
  question: string;
  answer: string;
}

const faqs: FAQ[] = [
  {
    question: "How does Emerald CMMS improve efficiency",
    answer:
      "Emerald CMMS automates processes, schedules tasks, and provides real-time tracking, resulting in up to a 40% increase in productivity. By reducing manual input and streamlining workflows, it ensures tasks are completed faster and more accurately.",
  },
  {
    question: "Can Emerald CMMS be accessed on mobile devices",
    answer:
      "Emerald is available on IOS devices and allows users to view and create assets as well as update or request work orders.",
  },
  {
    question: "How does the QR code feature work",
    answer:
      "When a QR code is scanned, all  information related to the asset will be displayed and the activity log will be updated with information such as current coordinates.",
  },
  {
    question: "Is Emerald CMMS suitable for my industry",
    answer:
      "If your industry involves managing a large list of assets or a multifaceted workforce completing operations across facilities, then Emerald is right for you.",
  },
  {
    question: "Why should I choose Emerald CMMS over spreadsheets",
    answer:
      "Using the Emerald platform over traditional spreadsheets offers significant advantages including improved scalability, real-time data updates, and automated processes that enhance efficiency and accuracy. Using Emerald will reduce human errors, provide advanced reporting and analytics, and offer cloud backed security features. Furthermore, they facilitate enhanced lifecycle and maintenance management, ensure compliance with regulatory requirements making it a superior choice for effective asset management in  organizations looking to modernize.",
  },
];

const Faqs: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    if (openIndex !== null && contentRefs.current[openIndex]) {
      const contentElement = contentRefs.current[openIndex];
      if (contentElement) {
        contentElement.style.maxHeight = contentElement.scrollHeight + "px";
      }
    }
  }, [openIndex]);

  return (
    <div className="container">
      <div className="flex justify-between lg:flex-nowrap flex-wrap items-center md:mt-20 mt-12">
        <div>
          <h1 className="md:text-4xl md:leading-9 md:font-medium font-semibold text-lg leading-7 md:max-w-[21rem] text-gray-900 font-inter">
            Frequently asked questions
          </h1>
        </div>

        <div className="lg:mt-0 mt-6 max-w-[42rem] w-full">
          <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
            {faqs.map((faq, index) => (
              <div key={index} className="py-4">
                <button
                  className="flex w-full items-center justify-between text-left text-gray-900"
                  onClick={() => handleToggle(index)}
                >
                  <span className="md:text-base text-sm font-medium leading-5 text-gray-900">
                    {faq.question}
                  </span>
                  <span className="ml-4 flex items-center">
                    {openIndex === index ? (
                      <ChevronUpIcon className="h-6 w-6 text-gray-500 transition-transform duration-300" />
                    ) : (
                      <ChevronDownIcon className="h-6 w-6 text-gray-500 transition-transform duration-300" />
                    )}
                  </span>
                </button>
                <div
                  ref={(el) => (contentRefs.current[index] = el)}
                  className={`mt-2 lg:pr-12 overflow-hidden transition-max-height duration-500 ease-in-out ${
                    openIndex === index ? "max-h-screen" : "max-h-0"
                  }`}
                  style={{
                    maxHeight:
                      openIndex === index
                        ? `${contentRefs.current[index]?.scrollHeight}px`
                        : "0px",
                  }}
                >
                  <p className="md:text-base md:leading-6 leading-5 font-normal text-xs text-gray-600">
                    {faq.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
