import React from "react";
import Button from "./shared-components/Button";
import { ArrowRightIcon } from "../assets/svg";
import boostImg from "../assets/boostImg.svg";

const Boost = () => {
  return (
    <div
      style={{ background: "linear-gradient(180deg, #FBFBFD 0%, #F9FBFE 100%" }}
    >
      <div className="container md:mt-20 mt-12 ">
        <div className="flex justify-between lg:flex-nowrap flex-wrap md:py-20 py-12 items-center">
          <div>
            <h1 className="md:text-4xl md:leading-9 md:font-medium font-semibold text-lg leading-7 md:max-w-[29rem] text-gray-900 font-inter">
              Ready to Boost Your Efficiency?
            </h1>
            <div className="mt-8">
              <a href="#footer">
                <Button
                  variant="default"
                  iconPosition="end"
                  icon={<ArrowRightIcon />}
                >
                  Join Wish List
                </Button>
              </a>
            </div>
          </div>

          <div className="lg:mt-0 mt-12">
            <img src={boostImg} alt="boostImg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boost;
