import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { Navigation, Pagination, EffectFade, Autoplay } from "swiper/modules";
import "./testimonial.css";
import cameron from "../../assets/cameron.svg";
import graciela from "../../assets/graciela.svg";
import michael from "../../assets/michael.svg";
import { motion } from "framer-motion";

const testimonialData = [
  {
    id: 1,
    review:
      "Emerald Data Solution has transformed our campus management. With real-time tracking and automated scheduling, our facilities are always in top condition, allowing us to focus on educating our students.",
    name: "Cameron Williamson",
    userProfile: cameron,
    position: "CEO",
  },
  {
    id: 2,
    review:
      "Emerald Data Solution has helped us immensely in keeping track of all our assets and ensuring everything runs smoothly across departments. We’ve seen a significant improvement in day-to-day efficiency.",
    name: "Michael Bacon",
    userProfile: graciela,
    position: "Facilities Manager",
  },
  {
    id: 3,
    review:
      "Emerald Data Solution provides us with the transparency we need to make data-driven decisions. Their tools have made it easier to track the progress of our operations and ensure everything is in place.",
    name: "Jane Smith",
    userProfile: michael,
    position: "Chief Operating Officer",
  },
];

const Testimonial = () => {
  return (
    <div className="bg-[#13171C] lg:mt-14 mt-12 py-20 relative">
      <motion.div
        className="container flex flex-col items-center"
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
      >
        <div className="testimonialSwiper max-w-full relative">
          <Swiper
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
              el: ".swiper-pagination",
            }}
            effect="fade"
            fadeEffect={{ crossFade: true }}
            speed={1200}
            loop={true}
            modules={[Navigation, Pagination, EffectFade, Autoplay]}
            className="mySwiper"
          >
            {testimonialData.map((item) => (
              <SwiperSlide key={item.id}>
                <div className="flex flex-col items-center text-center">
                  <p className="lg:max-w-[49rem] max-w-[17rem] w-full font-inter font-light lg:text-2xl text-sm text-white md:min-h-[144px]">
                    {item.review}
                  </p>
                  <img
                    src={item.userProfile}
                    alt="profile"
                    className="mt-8 rounded-full w-12 h-12"
                  />
                  <p className="mt-4 lg:text-base text-xs font-medium font-inter lg:leading-5 text-white">
                    {item.name}
                  </p>
                  <span className="lg:leading-5 lg:text-xs text-[0.625rem] font-medium font-inter text-[#CCD1D8] mt-2">
                    {item.position}
                  </span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Custom Navigation Arrows */}
          <div className="swiper-button-prev testimponialSwipper">
            {/* Background image for left arrow */}
          </div>
          <div className="swiper-button-next testimponialSwipper">
            {/* Background image for right arrow */}
          </div>

          {/* Pagination Dots */}
          <div className="swiper-pagination "></div>
        </div>
      </motion.div>
    </div>
  );
};

export default Testimonial;
