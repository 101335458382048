import React, { useState } from "react";
import { InfoIcon } from "../../../assets/svg";
import { motion } from "framer-motion";

const ComparePricing: React.FC = () => {
  const [annualBilling, setAnnualBilling] = useState(false);

  // Pricing plan data
  const plans = [
    {
      name: "Starter",
      price: annualBilling ? "533" : "599",
      description: "Essentials to begin",
      buttonText: "Contact Us",
      coreFeatures: [
        "Unlimited",
        "Unlimited",
        "Up to 3",
        "Up to 250 (3 years archived)",
        "-",
        "-",
        "Coming Soon",
      ],
      additionalFeatures: ["✔", "✔", "✔", "✔", "-", "-"],
    },
    {
      name: "Professional",
      price: annualBilling ? "1067" : "1199",
      description: "Advanced features for growth",
      buttonText: "Contact Us",
      coreFeatures: [
        "Unlimited",
        "Unlimited",
        "Up to 7",
        "Up to 500 (3 years archived)",
        "Up to 5000",
        "Up to 9 canned reports",
        "Coming Soon",
      ],
      additionalFeatures: ["✔", "✔", "✔", "✔", "✔", "✔"],
    },
    {
      name: "Enterprise",
      price: "Let’s talk",
      description: "Comprehensive solutions for scale",
      buttonText: "Contact Us",
      coreFeatures: [
        "Unlimited",
        "Unlimited",
        "Unlimited",
        "Unlimited",
        "Unlimited",
        "Ai SQL report generator (Coming Soon)",
        "Coming Soon",
      ],
      additionalFeatures: ["✔", "✔", "✔", "✔", "✔", "✔"],
    },
  ];

  // Core and feature titles
  const coreFeaturesTitles = [
    "Total Users",
    "Total Work Orders",
    "Total Sites",
    "Total Assets",
    "Total Purchase Orders",
    "Total Reports",
    "PK-12 Financial Calculator",
  ];

  const additionalFeaturesTitles = [
    "Work Orders",
    "Site Management",
    "Vendor Management",
    "Asset Management",
    "Reports & Analytics",
    "Purchase Orders",
  ];

  return (
    <section className="md:mt-48 mt-14 ">
      <div className="container">
        {/* Heading */}
        <h2 className="lg:text-4xl text-lg md:leading-9 lg:font-medium font-semibold leading-7 text-center text-gray-900 font-inter">
          Compare Plans
        </h2>

        <motion.div
          className="flex flex-col md:flex-row mt-12 gap-[1.125rem]"
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          {/* Left Features Section */}
          <div className="w-full md:max-w-[16.65rem] pt-8">
            <div className="mb-10">
              <h3 className="text-[1.75rem]  font-semibold text-grey.900">
                Set up your site, pick a plan later
              </h3>
              <p className="text-gray-500 text-base font-normal leading-[172.023%] mt-5">
                Choose the plan that's right for your business.
              </p>
              {/* Toggle */}
              <div className="flex  mt-[1.25rem]">
                <label className="inline-flex items-center cursor-pointer">
                  <div className="relative">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={annualBilling}
                      onChange={() => setAnnualBilling(!annualBilling)}
                    />
                    <div
                      className={`
                        w-10 h-5 rounded-full transition-colors duration-300
                        ${annualBilling ? "bg-green-500" : "bg-gray-300"}
                        peer-checked:after:translate-x-5
                        after:content-[''] after:absolute after:top-0.5 after:left-[2px]
                        after:bg-white after:rounded-full after:h-4 after:w-4 after:transition-all
                      `}
                    ></div>
                  </div>
                  <span className="ml-2 text-xs font-medium text-gray-500">
                    Save 11% Annually
                  </span>
                </label>
              </div>
            </div>

            {/* Core Features Section */}
            <div className="mb-6 mt-16">
              <h3 className="text-base text-grey-700 font-semibold ">Core</h3>
              <ul className="mt-[1.31rem] text-grey-800 text-xs  flex flex-col gap-[0.56rem]">
                {coreFeaturesTitles.map((title, index) => (
                  <li
                    className="pb-2 border-b border-[#E8ECF3] flex gap-2 items-center"
                    key={index}
                  >
                    {title} <InfoIcon />{" "}
                  </li>
                ))}
              </ul>
            </div>

            {/* Additional Features Section */}
            <div className="mt-[2.81rem]">
              <h3 className="text-base text-grey-700 font-semibold">
                Features
              </h3>
              <ul className="mt-[1.31rem] text-grey-800 text-xs flex flex-col gap-[0.56rem]">
                {additionalFeaturesTitles.map((title, index) => (
                  <li className="pb-2 border-b border-[#E8ECF3]" key={index}>
                    {title}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Pricing Plans */}
          <div className=" flex flex-col md:flex-row gap-[1.125rem]">
            {plans.map((plan, index) => (
              <div
                key={index}
                className=" max-w-[16.65rem] w-full text-center bg-[#F9FAFD] rounded-xl p-[2.12rem]"
              >
                <h3 className="text-2xl font-grey-900 font-bold">
                  {plan.name}
                </h3>
                <p className="mt-[0.38rem] leading-[130%] text-sm text-gray-500 min-h-[2.55rem]">
                  {plan.description}
                </p>
                <p className="text-xl text-green-500 font-semibold mt-4">
                  {plan.price}{" "}
                  <span className="text-sm text-grey-400"> {annualBilling ? "/ Yearly" : "/ Monthly"} </span>
                </p>
                <button className="mt-[1.75rem] w-full bg-green-500 text-white py-[0.87rem] px-6 rounded-lg text-sm leading-5 font-medium whitespace-nowrap">
                  {plan.buttonText}
                </button>

                {/* Core Features List */}
                <ul className="mt-[6.3rem] text-grey-800 text-center text-xs flex flex-col gap-[0.56rem] min-h-[18rem]">
                  {plan.coreFeatures.map((feature, i) => (
                    <li
                      className="pb-2  border-b border-[#E8ECF3] whitespace-nowrap"
                      key={i}
                    >
                      {feature}
                    </li>
                  ))}
                </ul>

                {/* Additional Features List */}
                <ul className="mt-[2.8rem] text-grey-800 text-center text-xs flex flex-col gap-[0.56rem]">
                  {plan.additionalFeatures.map((feature, i) => (
                    <li className="pb-2 border-b border-[#E8ECF3]" key={i}>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default ComparePricing;
