import React from "react";

interface InputProps {
  label: string;
  placeholder: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // Add any other props you might need, such as onChange, value, etc.
}

const Input: React.FC<InputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  ...props
}) => {
  return (
    <div className="mb-5">
      <label className="block text-left text-sm font-medium leading-6 text-gray-700 mb-[0.37rem]">
        {label}
      </label>
      <input
        className="md:w-[24.25rem] w-[22rem] py-2.5 px-3.5 bg-[#FBFCFE] box-shadow=[0px 1px 2px 0px rgba(16, 24, 40, 0.05)] border border-grey-100 rounded-lg text-700-700 text-sm placeholder-[#5C6B82] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};

export default Input;
