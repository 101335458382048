import React from "react";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-4xl font-bold">404 - Page Not Found</h1>
      <p className="text-lg mt-4">
        The page you are looking for does not exist.
      </p>
      <a href="/" className="text-blue-500 mt-8">
        Return to Home Page
      </a>
    </div>
  );
};

export default NotFoundPage;
