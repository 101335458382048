import React from "react";
import wallnetHill from "../assets/walnetHills.svg";
import medallionSchhol from "../assets/medallionSchool.svg";
import oakwood from "../assets/oakWood.svg";
import nationalJunior from "../assets/juniorHonor.svg";
import media from "../assets/media.svg";
import { motion } from "framer-motion";

const logos = [wallnetHill, medallionSchhol, oakwood, nationalJunior, media];

const HereBranding = () => {
  return (
    <div className="overflow-hidden w-full">
      <motion.div
        className="flex lg:mt-24 mt-24  justify-center gap-40 w-full lg:flex-nowrap  lg:pb-8 pb-6"
        animate={{ x: ["0%", "-50%"] }} // Scroll by half to show seamless repeat
        transition={{
          repeat: Infinity,
          duration: 10, // Adjust for desired speed
          ease: "linear",
          repeatType: "loop",
        }}
      >
        {/* First set of logos */}
        {logos.map((logo, index) => (
          <img key={index} src={logo} alt={`logo-${index}`} />
        ))}

        {/* Duplicate set of logos for seamless loop */}
        {logos.map((logo, index) => (
          <img key={`duplicate-${index}`} src={logo} alt={`logo-${index}`} />
        ))}
        {logos.map((logo, index) => (
          <img key={`duplicate-${index}`} src={logo} alt={`logo-${index}`} />
        ))}
        {logos.map((logo, index) => (
          <img key={`duplicate-${index}`} src={logo} alt={`logo-${index}`} />
        ))}
      </motion.div>
    </div>
  );
};

export default HereBranding;
