"use client";

import { useState, useMemo } from "react";
import { CheckIcon } from "../../../assets/svg";
import { motion } from "framer-motion";

// Type definition for a pricing tier
interface Tier {
  name: string;
  id: string;
  featured: boolean;
  description: string;
  price: {
    monthly: string;
    annually: string;
  };
  highlights: string[];
  features: string[];
  buttonLabel: string;
}

const usePricingData = () => {
  const frequencies = useMemo(
    () => [
      { value: "monthly", label: "Monthly" },
      { value: "annually", label: "Annually" },
    ],
    []
  );

  const tiers: Tier[] = useMemo(
    () => [
      {
        name: "Starter",
        id: "tier-starter",
        featured: false,
        description: "Essentials to begin",
        price: { monthly: "$699", annually: "$599" },
        highlights: ["Up to 3 Sites", "Up to 250 Assets"],
        features: [
          "Work Orders",
          "Asset Management",
          "Site Management",
          "User Management",
        ],
        buttonLabel: "Coming Soon",
      },
      {
        name: "Professional",
        id: "tier-professional",
        featured: true,
        description: "Advanced features for growth",
        price: { monthly: "$1399", annually: "$1199" },
        highlights: ["Up to 7 Sites", "Up to 500 Assets"],
        features: [
          "Work Orders",
          "Asset Management",
          "Site Management",
          "User Management",
          "Purchase Orders",
        ],
        buttonLabel: "Coming Soon",
      },
      {
        name: "Enterprise",
        id: "tier-enterprise",
        featured: false,
        description: "Comprehensive solutions for scale",
        price: { monthly: "Let’s talk", annually: "Let’s talk" },
        highlights: ["Unlimited Sites", "Unlimited Assets"],
        features: [
          "Work Orders",
          "Asset Management",
          "Site Management",
          "User Management",
          "Purchase Orders",
        ],
        buttonLabel: "Coming Soon",
      },
    ],
    []
  );

  return { frequencies, tiers };
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function PricingSection() {
  const { frequencies, tiers } = usePricingData();
  const [frequency, setFrequency] = useState(frequencies[1]);

  const toggleFrequency = () => {
    setFrequency(
      frequency.value === "monthly" ? frequencies[1] : frequencies[0]
    );
  };

  const priceDisplay = (tier: Tier) =>
    tier.price[frequency.value as "monthly" | "annually"];

  return (
    <div className="container md:mt-20 mt-12 ">
      <h2 className="lg:text-4xl text-lg md:leading-9 lg:font-medium font-semibold leading-7 text-center text-gray-900 font-inter">
        Choose the plan that fits your needs.
      </h2>

      {/* Toggle for Monthly/Annually */}
      <div className="flex justify-center mt-6">
        <label className="inline-flex items-center cursor-pointer">
          <div className="relative">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={frequency.value === "annually"}
              onChange={toggleFrequency}
            />
            <div
              className={`
                w-10 h-5 rounded-full transition-colors duration-300
                ${
                  frequency.value === "annually"
                    ? "bg-green-500"
                    : "bg-gray-300"
                }
                 peer-checked:after:translate-x-5
                        after:content-[''] after:absolute after:top-0.5 after:left-[2px]
                        after:bg-white after:rounded-full after:h-4 after:w-4 after:transition-all
              `}
            ></div>
          </div>
          <span className="ml-2 text-xs font-medium text-gray-500">
            Save 11% Annually
          </span>
        </label>
      </div>

      {/* Pricing Cards */}
      <motion.div
        className="flex flex-col lg:flex-row md:gap-1 gap-6 mt-14"
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        {tiers.map((tier) => (
          <div
            key={tier.id}
            className={classNames(
              tier.featured
                ? "bg-white shadow-[0_2px_6px_rgba(0,0,0,0.04),_0_5px_18px_rgba(0,0,0,0.06),_0_24px_83px_rgba(0,0,0,0.10)]  md:scale-105 pb-[4rem]"
                : "bg-[#EAFAF8]",
              "rounded-[1.25rem] md:px-7 px-[1.66rem] md:py-[2.38rem] py-[2.25rem] flex-1"
            )}
          >
            {/* Plan Name */}
            <h3 className="text-[1.75rem] leading-[3.15425rem] text-[#111019] font-bold">
              {tier.name}
            </h3>
            <p className="text-gray-900 text-base font-[inter] font-normal">
              {tier.description}
            </p>

            {/* Price */}
            <p className="mt-[2.625rem] text-[1.75rem] font-semibold text-[#111019]">
              {priceDisplay(tier)}
              {priceDisplay(tier) !== "Let’s talk" && (
                <span className="text-sm font-normal text-[#212121]">
                  {frequency.value === "annually" ? "/Yearly" : "/Monthly"}
                </span>
              )}
            </p>

            {/* Button */}
            <button className="mt-7 w-full bg-green-500 text-white py-[0.88rem] px-6 rounded-lg text-sm leading-5 font-medium">
              {tier.buttonLabel}
            </button>

            {/* Highlights */}
            <ul className="flex flex-col gap-3 text-sm text-gray-900 list-disc mt-[2.63rem]">
              {tier.highlights.map((highlight) => (
                <li key={highlight} className="ml-4">
                  <span>{highlight}</span>
                </li>
              ))}
            </ul>

            <hr
              className={`mt-[2.63rem] h-[2px] ${
                tier.featured ? "border-[#F1F0F3]" : "border-[#54BAA3]"
              }`}
            />

            {/* Features */}
            <div className="text-left mt-6">
              <h4 className="text-sm font-semibold text-gray-900">
                Key Features
              </h4>
              <ul className="mt-6 flex flex-col gap-4 text-base text-gray-800 font-normal">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-6 items-center">
                    <CheckIcon />
                    <p>{feature}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
}
