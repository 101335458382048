import React, { useEffect, useState } from "react";

import farmer from "../../assets/farmer.svg";
import manager from "../../assets/manager.png";
import engineer from "../../assets/engineer.png";
import managerMobile from "../../assets/managerMobile.png";
import farmerMobile from "../../assets/farmerMobile.png";
import "./emerald.css";
import Slider from "./Slider";

const slideData = [
  {
    id: 1,
    title: "Instant Access",
    desc: "Access vendor info, real time reports, and track inventory effortlessly right at your finger tips.",
  },
  {
    id: 2,
    title: "Instant Access",
    desc: "Access vendor info, real time reports, and track inventory effortlessly right at your finger tips.",
  },
  {
    id: 3,
    title: "Instant Access",
    desc: "Access vendor info, real time reports, and track inventory effortlessly right at your finger tips.",
  },
  {
    id: 4,
    title: "Instant Access",
    desc: "Access vendor info, real time reports, and track inventory effortlessly right at your finger tips.",
  },
  {
    id: 5,
    title: "Instant Access",
    desc: "Access vendor info, real time reports, and track inventory effortlessly right at your finger tips.",
  },
];
const WhyEmerald = () => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 2000);

    return () => clearInterval(interval);
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % slideData.length);
  };

  return (
    <div className="md:mt-20 mt-12 md:py-20 py-12 container " id="eds">
      <div className="flex justify-between items-center lg:flex-nowrap flex-wrap ">
        <div className="lg:w-[50%] w-full">
          <h1 className="md:text-4xl md:leading-9 md:font-medium font-semibold text-lg leading-7  text-gray-900 mt-6 font-inter">
            Why Emerald?
          </h1>

          <Slider />
        </div>

        <div className="lg:mt-0 mt-10">
          <div className="flex gap-[18px]">
            <img src={farmer} alt="farmer" className="hidden lg:block" />
            <img
              src={farmerMobile}
              alt="farmer"
              className="block lg:hidden w-[55%] h-full "
            />

            <img
              src={manager}
              alt="manager"
              className="hidden lg:block w-[13rem]"
            />
            <img
              src={managerMobile}
              alt="manager"
              className="block lg:hidden w-[40%]  "
            />
          </div>
          <div className="mt-[18px]">
            <img src={engineer} alt="engineer" className="w-[31.5rem]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyEmerald;
