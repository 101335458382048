import React from "react";
import HeroSection from "../components/HeroSection";
import ChallengesSection from "../components/ChallengesSection";
import Efficiency from "../components/Efficiency";
import FeaturesSection from "../components/FeaturesSection";
import Faqs from "../components/Faqs";
import Boost from "../components/Boost";
// import UserSection from "../components/UserSection";
import Solution from "../components/solution/Solution";
import WhyEmerald from "../components/emerald/WhyEmerald";
import Testimonial from "../components/testimonial/Testimonial";

const LandingPage = () => {
  return (
    <div>
      <HeroSection />
      <ChallengesSection />
      <Efficiency />
      <FeaturesSection />
      <Solution />
      <WhyEmerald />
      <Testimonial />
      {/* <UserSection /> */}
      <Faqs />
      <Boost />
    </div>
  );
};

export default LandingPage;
