import React, { useState } from "react";
import { motion, useInView } from "framer-motion";
import Chip from "./shared-components/Chip";
import EfficiencyTitels from "./shared-components/EfficiencyTitels";
import forcasting from "../assets/forcasting.png";
import tractActivity from "../assets/tractActivity.png";
import qr from "../assets/qr.png";
import spreadSheet from "../assets/spreadSheet.png";
import activityOrder from "../assets/activityOrders.png";

const Efficiency = () => {
  const refDivs = React.useRef(null); // Single ref for both divs
  const isDivsInView = useInView(refDivs, { once: true }); // Single view state for both divs

  const [isHovered1, setIsHovered1] = useState(false); // Hover state for first motion div
  const [isHovered2, setIsHovered2] = useState(false); // Hover state for second motion div
  const [isHovered3, setIsHovered3] = useState(false); // Hover state for third motion div
  const [isHovered4, setIsHovered4] = useState(false);

  return (
    <div className="container" id="solutions" ref={refDivs}>
      {" "}
      {/* Attach ref here */}
      <div className="mt-40">
        <div className="flex flex-col justify-center text-center">
          <h1 className="md:text-4xl text-lg lg:leading-9 leading-6 lg:font-medium font-semibold text-center text-gray-900 font-inter">
            Unleash the Future of Efficiency
          </h1>
          <p className="lg:text-lg text-xs font-normal text-center lg:mt-6 mt-4 leading-7 text-gray-800 font-inter">
            Discover the strategic advantage Emerald brings to propel your
            organization into the future
          </p>
        </div>

        {/* First Two Flex Containers with Animation */}
        <div className="flex md:flex-nowrap flex-wrap md:gap-[18px] gap-4 md:mt-20 mt-12">
          {/* First Animated Div */}
          <div className="">
            <motion.div
              className="bg-black md:p-9 p-5 rounded-xl"
              initial={{ opacity: 0, rotate: -5 }} // Start at -5 degrees
              animate={{
                rotate: isDivsInView ? 0 : -5, // Animate to 0 when in view
                opacity: isDivsInView ? 1 : 0,
              }}
              transition={{ duration: 1, ease: "easeInOut" }}
              onHoverStart={() => setIsHovered4(true)}
              onHoverEnd={() => setIsHovered4(false)}
            >
              <Chip text="Analytics for Better Outcomes" bgColor="white" />
              <div className="md:mt-7 mt-4">
                <EfficiencyTitels
                  title="Plan with Accurate Forecasting"
                  desc="Emerald revolutionizes your planning with advanced forecasting tools. Anticipate needs, schedule proactively, and optimize resources to keep operations running smoothly."
                  isWhite={true}
                  marginTopMobile="mt-6"
                  marginTopDesktop="md:mt-4"
                  maxWidthH2="lg:max-w-[20rem]"
                  maxWidtMobilehH2="max-w-[9rem]"
                />
              </div>
              <div className="md:mt-20 mt-7">
                <motion.img
                  src={forcasting}
                  alt="forcasting"
                  className="w-[31rem]"
                  animate={{ scale: isHovered4 ? 1.1 : 1 }}
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                />
              </div>
            </motion.div>

            <motion.div
              className="md:mt-[18px] mt-4 rounded-xl overflow-hidden"
              style={{
                background: isHovered1
                  ? "linear-gradient(180deg, #F1F4F9 0%, #E4EFFF 100%)"
                  : "linear-gradient(180deg, #FBFBFD 0%, #F9FBFE 100%)",
              }}
              initial={{ opacity: 0, rotate: -5 }} // Start at -5 degrees
              animate={{
                rotate: isDivsInView ? 0 : -5, // Animate to 0 when in view
                opacity: isDivsInView ? 1 : 0,
              }}
              transition={{ duration: 1, ease: "easeInOut" }}
              onHoverStart={() => setIsHovered1(true)}
              onHoverEnd={() => setIsHovered1(false)}
            >
              <div className="md:p-9 p-6 md:pb-0">
                <Chip text="Communicate with Purpose" />
                <div className="md:mt-8 mt-5">
                  <EfficiencyTitels
                    title="Plan, Schedule, and Track Work Orders"
                    desc="Emerald revolutionizes your planning with advanced forecasting tools. Anticipate needs, schedule proactively, and optimize resources to keep operations running smoothly."
                    marginTopMobile="mt-2"
                    marginTopDesktop="md:mt-6"
                    maxWidthH2="lg:max-w-[27rem]"
                    maxWidtMobilehH2="max-w-[14rem]"
                  />
                </div>
              </div>
              <div className="md:mt-5 mt-6 flex justify-end">
                <motion.img
                  src={tractActivity}
                  alt="tractActivity"
                  className="w-[28rem]"
                  animate={{ scale: isHovered1 ? 1.1 : 1 }}
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                />
              </div>
            </motion.div>
          </div>

          {/* Second Animated Div */}
          <div>
            <motion.div
              className="rounded-xl overflow-hidden"
              style={{
                background: isHovered2
                  ? "linear-gradient(180deg, #F1F4F9 0%, #E4EFFF 100%)"
                  : "linear-gradient(180deg, #FBFBFD 0%, #F9FBFE 100%)",
              }}
              ref={refDivs}
              initial={{ opacity: 0, rotate: 5 }} // Start at 5 degrees
              animate={{
                rotate: isDivsInView ? 0 : 5, // Animate to 0 when in view
                opacity: isDivsInView ? 1 : 0,
              }}
              transition={{ duration: 1, ease: "easeInOut" }}
              onHoverStart={() => setIsHovered2(true)}
              onHoverEnd={() => setIsHovered2(false)}
            >
              <div className="md:p-9 p-5">
                <Chip text="Access Information Instantly" />
                <div className="md:mt-7 mt-4">
                  <EfficiencyTitels
                    title="Instant Access with QR Codes"
                    desc="Scan QR codes to instantly retrieve asset details allowing Emerald to record additional information such as current coordinates."
                    marginTopMobile="mt-4"
                    marginTopDesktop="md:mt-4"
                    maxWidthH2="lg:max-w-[20rem]"
                    maxWidtMobilehH2="max-w-[9.5rem]"
                  />
                </div>
              </div>

              <div className="md:mt-20 mt-7 flex justify-center">
                <motion.img
                  src={qr}
                  alt="qr"
                  className="lg:w-[22rem] w-[16rem]"
                  animate={{ scale: isHovered2 ? 1.1 : 1 }}
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                />
              </div>
            </motion.div>

            <motion.div
              className="bg-[#EBF7F5] md:p-9 p-5 rounded-xl md:mt-[18px] mt-4"
              style={{
                background: isHovered3 ? "#E3FCF7" : "#EBF7F5",
              }}
              initial={{ opacity: 0, rotate: 5 }} // Start at 5 degrees
              animate={{
                rotate: isDivsInView ? 0 : 5, // Animate to 0 when in view
                opacity: isDivsInView ? 1 : 0,
              }}
              transition={{ duration: 1, ease: "easeInOut" }}
              onHoverStart={() => setIsHovered3(true)}
              onHoverEnd={() => setIsHovered3(false)}
            >
              <Chip text="Transform Your Perspective" />
              <div className="md:mt-7 mt-4">
                <EfficiencyTitels
                  title="Move Beyond Spreadsheets"
                  desc="Every change in inventory is visible to your entire team in real time, eliminating data discrepancies across user profiles."
                  marginTopMobile="mt-6"
                  marginTopDesktop="md:mt-4"
                />
              </div>
              <div className="md:mt-16 mt-7 md:pb-12">
                <motion.img
                  src={spreadSheet}
                  alt="spreadSheet"
                  className="w-[32rem]"
                  animate={{ scale: isHovered3 ? 1.1 : 1 }}
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                />
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Efficiency;
