interface iconProps {
  color?: string;
}

export const ArrowRightIcon = ({ color = "white" }: iconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.333 4.66675L4.66634 11.3334"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.33301 4.66675H11.333V10.6667"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const WatchIcon = () => {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="28" height="28" rx="14" fill="#E6F5F1" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.333 7.33325V20.6666L21.1663 13.9999L10.333 7.33325Z"
        fill="#009875"
        stroke="#009875"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4678 4.13745C13.5898 4.25941 13.6583 4.42481 13.6583 4.59727C13.6583 4.76973 13.5898 4.93513 13.4678 5.0571L6.31353 12.2114C6.19156 12.3333 6.02616 12.4018 5.8537 12.4018C5.68124 12.4018 5.51584 12.3333 5.39387 12.2114L2.14192 8.95945C2.0798 8.89945 2.03025 8.82769 1.99616 8.74834C1.96208 8.66899 1.94413 8.58364 1.94338 8.49728C1.94263 8.41092 1.95909 8.32528 1.99179 8.24535C2.02449 8.16542 2.07279 8.0928 2.13385 8.03174C2.19492 7.97067 2.26754 7.92237 2.34747 7.88967C2.4274 7.85697 2.51304 7.84051 2.5994 7.84126C2.68576 7.84201 2.77111 7.85996 2.85046 7.89404C2.92981 7.92813 3.00157 7.97768 3.06157 8.0398L5.8537 10.8319L12.5482 4.13745C12.6701 4.01552 12.8355 3.94702 13.008 3.94702C13.1805 3.94702 13.3459 4.01552 13.4678 4.13745Z"
        fill="#333B48"
      />
    </svg>
  );
};

export const InfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7 5.25H7.00583M6.41667 7H7V9.33333H7.58333M1.75 7C1.75 7.68944 1.8858 8.37213 2.14963 9.00909C2.41347 9.64605 2.80018 10.2248 3.28769 10.7123C3.7752 11.1998 4.35395 11.5865 4.99091 11.8504C5.62787 12.1142 6.31056 12.25 7 12.25C7.68944 12.25 8.37213 12.1142 9.00909 11.8504C9.64605 11.5865 10.2248 11.1998 10.7123 10.7123C11.1998 10.2248 11.5865 9.64605 11.8504 9.00909C12.1142 8.37213 12.25 7.68944 12.25 7C12.25 5.60761 11.6969 4.27226 10.7123 3.28769C9.72774 2.30312 8.39239 1.75 7 1.75C5.60761 1.75 4.27226 2.30312 3.28769 3.28769C2.30312 4.27226 1.75 5.60761 1.75 7Z"
        stroke="#333B48"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const AssetsTrackingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="63"
      viewBox="0 0 54 63"
      fill="none"
    >
      <path
        d="M17.5263 48.5789H11.3158M11.3158 48.5789C8.84508 48.5789 6.47558 47.5974 4.72853 45.8504C2.98148 44.1033 2 41.7338 2 39.2631V20.6316C2 18.1609 2.98148 15.7914 4.72853 14.0443C6.47558 12.2973 8.84508 11.3158 11.3158 11.3158M11.3158 48.5789V57.8947H20.6316M11.3158 11.3158H29.9474M11.3158 11.3158V2H29.9474V11.3158M29.9474 11.3158C32.4181 11.3158 34.7876 12.2973 36.5346 14.0443C38.2817 15.7914 39.2631 18.1609 39.2631 20.6316V26.8421M46.0953 55.4105L51.6848 61M29.9474 48.5789C29.9474 51.0496 30.9288 53.4191 32.6759 55.1662C34.4229 56.9132 36.7924 57.8947 39.2631 57.8947C41.7338 57.8947 44.1033 56.9132 45.8504 55.1662C47.5974 53.4191 48.5789 51.0496 48.5789 48.5789C48.5789 46.1082 47.5974 43.7387 45.8504 41.9917C44.1033 40.2446 41.7338 39.2631 39.2631 39.2631C36.7924 39.2631 34.4229 40.2446 32.6759 41.9917C30.9288 43.7387 29.9474 46.1082 29.9474 48.5789Z"
        stroke="#272D37"
        stroke-width="3.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const VendorTrackingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M8 57.9473V51.8421C8 48.6036 9.28646 45.4979 11.5764 43.2079C13.8663 40.918 16.9721 39.6316 20.2105 39.6316H24.7895M51.3479 55.5053L56.8427 61M14.1053 15.2105C14.1053 18.4489 15.3917 21.5547 17.6816 23.8447C19.9716 26.1346 23.0773 27.421 26.3158 27.421C29.5542 27.421 32.66 26.1346 34.9499 23.8447C37.2398 21.5547 38.5263 18.4489 38.5263 15.2105C38.5263 11.9721 37.2398 8.86629 34.9499 6.57638C32.66 4.28646 29.5542 3 26.3158 3C23.0773 3 19.9716 4.28646 17.6816 6.57638C15.3917 8.86629 14.1053 11.9721 14.1053 15.2105ZM35.4737 48.7894C35.4737 51.2183 36.4385 53.5476 38.1559 55.2651C39.8734 56.9825 42.2027 57.9473 44.6316 57.9473C47.0604 57.9473 49.3897 56.9825 51.1072 55.2651C52.8246 53.5476 53.7894 51.2183 53.7894 48.7894C53.7894 46.3606 52.8246 44.0313 51.1072 42.3138C49.3897 40.5964 47.0604 39.6316 44.6316 39.6316C42.2027 39.6316 39.8734 40.5964 38.1559 42.3138C36.4385 44.0313 35.4737 46.3606 35.4737 48.7894Z"
        stroke="#272D37"
        stroke-width="3.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const UnplannedWorkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M41.7186 58.8122H13.3125C11.6383 58.8122 10.0327 58.1471 8.84888 56.9633C7.66506 55.7795 7 54.1739 7 52.4997V14.6249C7 12.9508 7.66506 11.3452 8.84888 10.1613C10.0327 8.97753 11.6383 8.31247 13.3125 8.31247H51.1873C52.8614 8.31247 54.467 8.97753 55.6509 10.1613C56.8347 11.3452 57.4997 12.9508 57.4997 14.6249V30.4061M44.8748 2V14.6249M19.6249 2V14.6249M7 27.2499H57.4997M29.0936 39.8748H32.2499V49.3435M54.3435 43.031V52.4997M54.3435 61.9684V62"
        stroke="#272D37"
        stroke-width="3.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const EmployeeTurnoverIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M61.2203 27.8339C60.4634 22.0591 57.6278 16.7582 53.2443 12.9233C48.8608 9.0885 43.2299 6.98269 37.4058 7.00011C31.5816 7.01752 25.9635 9.15696 21.603 13.0179C17.2425 16.8789 16.2301 20.209 14.9731 24.8408M34.4293 54.7734C40.4158 54.7734 43.1302 54.9332 48.3228 52.2953C53.5153 49.6575 57.5686 45.2177 59.7237 39.8069M61.2203 54.7732V39.8069H46.254"
        stroke="#272D37"
        stroke-width="3.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 57.7667V54.7735C3 53.1857 3.63072 51.663 4.75341 50.5404C5.8761 49.4177 7.39879 48.7869 8.98652 48.7869H14.973C16.5608 48.7869 18.0835 49.4177 19.2061 50.5404C20.3288 51.663 20.9596 53.1857 20.9596 54.7735V57.7667M22.4562 31.022C23.7439 31.3517 24.8853 32.1006 25.7003 33.1506C26.5154 34.2007 26.9578 35.4921 26.9578 36.8214C26.9578 38.1507 26.5154 39.4421 25.7003 40.4922C24.8853 41.5422 23.7439 42.2911 22.4562 42.6208M29.9393 57.7668V54.7735C29.9317 53.4522 29.4872 52.1706 28.6751 51.1283C27.8629 50.0861 26.7288 49.3418 25.4494 49.0115M5.99326 36.8139C5.99326 38.4016 6.62398 39.9243 7.74667 41.047C8.86936 42.1697 10.3921 42.8004 11.9798 42.8004C13.5675 42.8004 15.0902 42.1697 16.2129 41.047C17.3356 39.9243 17.9663 38.4016 17.9663 36.8139C17.9663 35.2262 17.3356 33.7035 16.2129 32.5808C15.0902 31.4581 13.5675 30.8274 11.9798 30.8274C10.3921 30.8274 8.86936 31.4581 7.74667 32.5808C6.62398 33.7035 5.99326 35.2262 5.99326 36.8139Z"
        stroke="#272D37"
        stroke-width="3.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const WarrantyTrackingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M58 46.4444C58 49.5092 56.7825 52.4484 54.6155 54.6155C52.4484 56.7825 49.5092 58 46.4444 58C43.3797 58 40.4405 56.7825 38.2734 54.6155C36.1063 52.4484 34.8889 49.5092 34.8889 46.4444C34.8889 43.3797 36.1063 40.4405 38.2734 38.2734C40.4405 36.1063 43.3797 34.8889 46.4444 34.8889M58 46.4444C58 43.3797 56.7825 40.4405 54.6155 38.2734C52.4484 36.1063 49.5092 34.8889 46.4444 34.8889M58 46.4444H46.4444V34.8889M32 6V17.5556C32 18.3217 32.3044 19.0565 32.8461 19.5983C33.3879 20.1401 34.1227 20.4444 34.8889 20.4444H46.4444M32 6H11.7778C10.2454 6 8.77582 6.60873 7.69227 7.69227C6.60873 8.77582 6 10.2454 6 11.7778V52.2222C6 53.7546 6.60873 55.2242 7.69227 56.3077C8.77582 57.3913 10.2454 58 11.7778 58H30.5556M32 6L46.4444 20.4444M46.4444 20.4444V26.2222"
        stroke="#272D37"
        stroke-width="3.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
