import { motion } from "framer-motion";
import Chip from "../shared-components/Chip";
import "./solution.css";
import "swiper/css";
import "swiper/css/navigation";

import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ArrowRightIcon } from "../../assets/svg";

import manufacture from "../../assets/manufacture.png";
import healthCare from "../../assets/healthCare.png";
import educationModal from "../../assets/educationModal.png";

const SlideData = [
  {
    id: 1,
    title: "Transforming Operational Efficiency in School Districts",
    desc: "School districts often face challenges with inefficient asset tracking, labor-intensive state reporting, and complex financial forecasting. Many educational institutions benefit from a tailored asset and maintenance management platform designed to streamline operations and automate essential processes, allowing them to focus more on educational goals.",
    boldDesc:
      "Through features like USAS code tagging, EDS simplifies and accelerates reporting processes, ensuring compliance while saving valuable time. Our work order management function improves asset longevity by minimizing repair delays, while the salary schedule forecasting tool aids district treasurers in producing accurate five-year financial forecasts. Together, these tools empower districts to make smarter financial decisions and allocate resources effectively.",
    bottomDesc:
      "With this platform, schools can expect a significant reduction in the time required for state reporting, freeing up staff for priority tasks. Operational efficiency rises as districts save on costs previously tied to asset loss and reactive maintenance. Additionally, enhanced financial planning tools support a clear, data-driven approach to budgeting and long-term resource management",
    imgTitle: "Education",
    imgModal: educationModal,
  },
  {
    id: 2,
    title:
      "Improving Patient Care Through Streamlined Asset Tracking and Maintenance",
    desc: "Healthcare facilities such as hospitals and clinics face unique operational needs, where equipment availability and compliance are essential to patient care. Frequent equipment downtime or lapses in maintenance can directly impact care quality, so a robust asset management system becomes critical.",
    boldDesc:
      "With real-time asset management and automated work order prioritization, EDS helps hospital staff easily locate and maintain critical medical equipment. Each asset, from diagnostic machines to surgical tools, can be tagged with QR codes, providing instant access to usage and maintenance records. Integrated vendor management ensures the supply chain remains responsive, reducing delays in sourcing vital medical supplies and parts.",
    bottomDesc:
      "For healthcare organizations, this system significantly reduces equipment downtime by streamlining work order processing and scheduling preventive maintenance. Improved equipment availability ensures timely patient care, while streamlined reporting and real-time tracking aid in meeting auditing and regulatory standards.",
    imgTitle: "Manufacturing",
    imgModal: manufacture,
  },
  {
    id: 3,
    title: "Enhancing Asset and Vendor Management for Efficient Production",
    desc: "Manufacturers depend on well-maintained machinery and efficient vendor relationships to keep production lines running smoothly. Delays in repairs or supply chain disruptions can hinder productivity and inflate operational costs, making effective asset management and vendor coordination vital.",
    boldDesc:
      "EDS allows manufacturers to monitor machinery, tooling, and inventory in real time, preventing unexpected breakdowns and optimizing maintenance schedules. The vendor management feature centralizes supplier interactions, ensuring that raw materials and parts are sourced without delay. Customizable financial reporting tools also offer insights into equipment costs, supporting strategic, data-driven decisions around budgeting and capital planning.",
    bottomDesc:
      "With these capabilities, manufacturers can lower maintenance costs by managing asset life cycles proactively and reducing emergency repairs. Improved vendor management minimizes production delays, keeping workflows uninterrupted and efficient. Overall, actionable financial insights enable manufacturers to optimize budgets and make informed decisions about resource allocation.",
    imgTitle: "Health Care",
    imgModal: healthCare,
  },
];

const Solution: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(SlideData[0]);

  const handleOpen = (item: (typeof SlideData)[0]) => {
    setSelectedItem(item);
    setOpen(true);
  };

  return (
    <div className="md:mt-28 mt-12 md:py-20 py-12 bg-[#F7FAFF]" id="industries">
      <div className="container">
        <Chip text="Case Studies" />
        <h1 className="md:text-4xl md:leading-9 md:font-medium font-semibold text-lg leading-7 text-gray-900 mt-6">
          Tailored Solutions for Every Industry
        </h1>

        <div className="solutionSwipper md:mt-12 mt-6">
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            navigation={true}
            breakpoints={{
              640: { slidesPerView: 2, spaceBetween: 20 },
              400: { slidesPerView: 1, spaceBetween: 20 },
              768: { slidesPerView: 2, spaceBetween: 30 },
              1024: { slidesPerView: 3, spaceBetween: 18 },
            }}
            modules={[Navigation]}
            className="mySwiper"
          >
            {SlideData.map((item) => (
              <SwiperSlide key={item.id}>
                <motion.div
                  className="explore-card bg-white rounded-3xl overflow-hidden relative group"
                  initial={{ opacity: 0, y: 150 }} // Cards start with opacity 0 and below view (y: 50)
                  whileInView={{ opacity: 1, y: 0 }} // When in view, opacity changes to 1 and y to 0 (i.e., moves up)
                  transition={{
                    duration: 1.2, // Slower transition duration
                    ease: "easeOut",
                  }}
                  viewport={{ once: true, amount: 0.2 }} // Animation happens once when 20% of the card is visible
                >
                  <motion.div
                    className="flex justify-center items-center h-[11rem] rounded-3xl relative overflow-hidden"
                    style={{
                      backgroundImage: `url(${item.imgModal})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    {/* Overlay and Title */}
                    <motion.div className="absolute inset-0 flex justify-center items-center">
                      {/* Overlay that becomes transparent on hover */}
                      <div className="absolute inset-0 bg-custom-gradient bg-no-repeat bg-cover blur-3px z-10 transition-opacity duration-300 group-hover:opacity-0"></div>

                      {/* Text layer that hides on hover */}
                      <p className="text-white text-lg font-medium font-inter relative z-10 transition-opacity duration-300 group-hover:hidden">
                        {item.imgTitle}
                      </p>
                    </motion.div>
                  </motion.div>

                  <div className="px-6 pb-6 pt-9">
                    <h3 className="md:text-lg text-sm font-semibold leading-6 text-gray-900 font-inter z-10 line-clamp-2">
                      {item.title}
                    </h3>
                    <p className="mt-3 md:text-sm text-xs leading-5 text-gray-500 font-normal line-clamp-3 font-inter">
                      {item.desc}
                    </p>

                    <button
                      className="flex gap-2 items-center mt-4 explore-button"
                      onClick={() => handleOpen(item)}
                    >
                      <span className="text-xs text-gray-600 leading-6 font-inter">
                        Explore More
                      </span>
                      <ArrowRightIcon color="" />
                    </button>
                  </div>
                </motion.div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="relative z-10"
      >
        <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10  flex justify-center items-center">
          <div className="flex items-end justify-center lg:p-12 p-8 text-center sm:items-center">
            <DialogPanel
              className="relative transform overflow-auto lg:p-12 p-4 lg:pt-8 rounded-lg bg-white text-left shadow-xl transition-all max-w-[77rem] w-full"
              style={{ height: "calc(100vh - 3rem)" }}
            >
              <div className="flex justify-end ">
                <button onClick={() => setOpen(false)}>
                  <XMarkIcon
                    aria-hidden="true"
                    className="h-6 w-6 font-inter"
                  />
                </button>
              </div>
              <div className="bg-[#EFFEFA] lg:p-8 p-4 flex lg:flex-nowrap flex-wrap lg:gap-20 gap-12 justify-between rounded-xl mt-8">
                <div>
                  <Chip text={selectedItem.imgTitle} />
                  <h2 className="lg:text-3xl text-lg font-medium lg:leading-8 leading-6 text-gray-900 max-w-[25rem] w-full lg:mt-6 mt-4 font-inter">
                    {selectedItem.title}
                  </h2>
                </div>
                <div className="lg:w-[36rem] w-full md:h-[25rem] h-[15rem] rounded-[1.25rem] overflow-hidden">
                  <img
                    src={selectedItem.imgModal}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>

              <div>
                <p className="lg:text-base text-xs lg:leading-6 leading-5 text-gray-800 lg:mt-12 mt-8 font-inter">
                  {selectedItem.desc}
                </p>
                <p className="lg:text-base text-xs  lg:leading-6 leading-5 text-gray-800 lg:mt-4  mt-8 font-inter">
                  {selectedItem.boldDesc}
                </p>
                <p className="lg:text-base text-xs lg:leading-6 leading-5 text-gray-800 lg:mt-4 mt-8 font-inter">
                  {selectedItem.bottomDesc}
                </p>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Solution;
