import React, { useState, useEffect } from "react";
import "./emerald.css";

const slides = [
  {
    title: "Instant Access",
    content:
      "Access vendor info, real time reports, and track inventory effortlessly right at your finger tips.",
  },
  {
    title: "Optimize Costs",
    content:
      "Monitor warranties and reduce expenses with our analytics tools to turn uninformed decisions into data driven initiatives.",
  },
  {
    title: "Streamline Management",
    content:
      "Organize quotes efficiently, ensure organization compliance, and manage team workloads rigorously for a well-structured operational framework.",
  },
  {
    title: "Boost Efficiency",
    content:
      "Enhance workflows, forecast costs accurately, and gain comprehensive insights to drive informed decision-making.",
  },
  {
    title: "Proactive Alerts",
    content:
      "Never miss key information with our automated alert system to keep you up to date with work order status and inventory challenges.",
  },
];

const Slider: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleDotClick = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="slider-container">
      <div className="navigation-dots">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          ></div>
        ))}
      </div>
      <div className="slider-wrapper">
        {slides.map((slide, index) => (
          <div
            className={`slide ${index === currentIndex ? "active" : ""}`}
            key={index}
          >
            <h2
              className={`lg:text-3xl text-base font-medium lg:leading-8 text-gray-800 font-inter lg:pl-0 pl-8 slide-in-top slide-title ${
                index === currentIndex ? "fade-in" : "fade-out"
              }`}
            >
              {slide.title}
            </h2>
            <p
              className={`lg:text-base text-xs font-normal lg:leading-6 leading-5 font-inter text-gray-600 max-w-[28rem] lg:min-w-[28rem] w-full lg:pl-0 pl-8 slide-content ${
                index === currentIndex ? "fade-in" : "fade-out"
              }`}
            >
              {slide.content}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
