import React from 'react'
import PricingPage from '../../components/pricing'

const Pricing = () => {
  return (
    <>
    <PricingPage />
    </>
  )
}

export default Pricing
